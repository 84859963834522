<!-- EndPage.vue -->
<template>
  <div class="chat-container end-page">
    <div class="logo-container">
      <img src="@/assets/logo.png" alt="Logo" class="logo">
    </div>
    
    <div class="final-message">
      {{ finalMessage }}
    </div>
    
    <div class="buttons-container">
      <button @click="handleRestart" class="btn btn-primary">
        Restart
      </button>
      <button @click="handleDownload" class="btn btn-secondary">
        Download Chat History
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'EndPage',
  computed: {
    ...mapState(['chatHistory']),
    finalMessage() {
      if (this.chatHistory.length > 0) {
        return this.chatHistory[this.chatHistory.length - 1].sys_message
      }
      return 'Chat ended'
    }
  },
  methods: {
    ...mapActions(['clearChatHistory']),
    async handleRestart() {
      await this.clearChatHistory()
      this.$router.push('/')
    },
    handleDownload() {
      const dataStr = JSON.stringify(this.chatHistory, null, 2)
      const dataBlob = new Blob([dataStr], { type: 'application/json' })
      const url = window.URL.createObjectURL(dataBlob)
      const link = document.createElement('a')
      link.href = url
      link.download = 'chat_history.json'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.end-page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.logo-container {
  width: 100px;
  height: 100px;
  margin: 10px 0;
  
  .logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.final-message {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #fff;
}

.buttons-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  
  .btn-secondary {
    background-color: #666;
    color: white;
  }
}
</style> 