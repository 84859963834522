<!-- ChatPage.vue -->
<template>
  <div class="chat-container chat-page">
    <div class="logo-container">
      <img src="@/assets/logo.png" alt="Logo" class="logo">
    </div>
    
    <div class="messages-container">
      <div class="message system" v-if="systemMessage">
        {{ systemMessage }}
      </div>
      <div v-if="isThinking" class="message system">
        大師思考中...
      </div>
    </div>
    
    <div class="input-container">
      <input 
        type="text" 
        v-model="userInput"
        @keyup.enter="handleSendMessage"
        class="input-field"
        placeholder="和大師說..."
        :disabled="isThinking"
      >
      <button 
        @click="handleSendMessage" 
        class="btn btn-primary"
        :disabled="isThinking || !userInput.trim()"
      >
        發送
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ChatPage',
  data() {
    return {
      userInput: '',
      isThinking: false
    }
  },
  computed: {
    ...mapState(['chatHistory']),
    systemMessage() {
      return this.chatHistory.length > 0 
        ? this.chatHistory[this.chatHistory.length - 1].sys_message 
        : ''
    }
  },
  methods: {
    ...mapActions(['sendMessage', 'getMessages']),
    async handleSendMessage() {
      if (!this.userInput.trim() || this.isThinking) return
      
      this.isThinking = true
      try {
        const response = await this.sendMessage({
          user_message: this.userInput.trim()
        })
        
        if (response.current_type === 'End') {
          this.$router.push('/end')
        }
      } catch (error) {
        console.error('Error sending message:', error)
        const errorMessage = error.response?.data?.error || error.message || '发送消息时发生错误'
        alert(`错误信息: ${errorMessage}`)
      } finally {
        this.isThinking = false
        this.userInput = ''
      }
    }
  },
  async created() {
    try {
      await this.getMessages()
    } catch (error) {
      console.error('Error fetching messages:', error)
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color:rgb(0, 0, 0);
}

.logo-container {
  width: 100px;
  height: 100px;
  margin: 10px auto;
  
  .logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  margin: 0 20px;
  border-radius: 8px;
  
  .message {
    margin: 10px 0;
    padding: 10px 15px;
    border-radius: 4px;
    max-width: 80%;
    
    &.system {
      background: none;
      color: #000000;
      align-self: center;
      box-shadow: none;
      font-size: 16px;
    }
  }
}

.input-container {
  padding: 20px;
  display: flex;
  gap: 10px;
  background:rgb(0, 0, 0);
  
  .input-field {
    flex: 1;
    padding: 12px 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    background-color: #ffffff;
    color: #000000;
    
    &::placeholder {
      color: #999;
    }
    
    &:disabled {
      background-color: #f5f5f5;
      color: #666;
    }
  }
  
  .btn {
    white-space: nowrap;
    padding: 12px 24px;
    border-radius: 8px;
    background-color:rgb(37, 37, 37);
    color: white;
    border: none;
    font-weight: 500;
    
    &:hover:not(:disabled) {
      background-color:rgb(46, 46, 46);
    }
    
    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
      background-color: #95a5a6;
    }
  }
}
</style> 