import axios from 'axios';

const api = axios.create({
    // baseURL: 'http://47.95.193.206:5432', // Backend API address
    baseURL:'/api',
    timeout: 1000000,
    headers: {
        'Content-Type': 'application/json'
    }
});

// 添加请求拦截器
api.interceptors.request.use(
    config => {
        console.log(`[API Request] ${config.method.toUpperCase()} ${config.url}`, config.data)
        return config
    },
    error => {
        console.error('[API Request Error]', error)
        return Promise.reject(error)
    }
)

// 添加响应拦截器
api.interceptors.response.use(
    response => {
        console.log(`[API Response] ${response.config.method.toUpperCase()} ${response.config.url}`, response.data)
        return response
    },
    error => {
        const errorResponse = error.response || error
        console.error('[API Response Error]', errorResponse)
        
        // 添加更多错误信息
        if (error.code === 'ECONNREFUSED') {
            error.message = '无法连接到服务器，请确保后端服务正在运行'
        } else if (error.response?.status === 500) {
            error.message = error.response.data.error || '服务器内部错误'
        }
        
        return Promise.reject(error)
    }
)

export default api; 