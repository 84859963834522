<!-- StartPage.vue -->
<template>
  <div class="chat-container start-page">
    <div class="logo-container">
      <img src="@/assets/logo.png" alt="Logo" class="logo">
    </div>
    <button @click="handleStart" class="btn btn-primary">Start</button>
    <div class="info-text">
      Copyright Ao Shen FOR DEMO USE ONLY
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'StartPage',
  methods: {
    ...mapActions(['clearChatHistory']),
    async handleStart() {
      await this.clearChatHistory()
      this.$router.push('/chat')
    }
  }
}
</script>

<style lang="scss" scoped>
.start-page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.logo-container {
  width: 200px;
  height: 200px;
  margin: 20px 0;
  
  .logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.info-text {
  margin-top: 20px;
  font-size: 14px;
  color: #888;
  text-align: center;
}
</style> 