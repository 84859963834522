import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api/config'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    chatHistory: []
  },
  getters: {
  },
  mutations: {
    SET_CHAT_HISTORY(state, history) {
      state.chatHistory = history
    },
    ADD_MESSAGE(state, { sys_message, user_message }) {
      state.chatHistory.push({ sys_message, user_message })
    },
    CLEAR_CHAT_HISTORY(state) {
      state.chatHistory = []
    }
  },
  actions: {
    async sendMessage({ commit, state }, { user_message }) {
      try {
        console.log('Sending message:', user_message)
        const response = await api.post('/send_message', {
          user_message,
          chat_history: state.chatHistory
        })
        
        console.log('Received response:', response.data)
        const sys_message = response.data.current_response
        
        // 前端维护聊天历史
        commit('ADD_MESSAGE', {
          sys_message,
          user_message
        })
        
        return response.data
      } catch (error) {
        console.error('Error sending message:', error)
        throw error
      }
    },
    
    async getMessages({ commit }) {
      try {
        const response = await api.get('/get_messages')
        commit('SET_CHAT_HISTORY', response.data.chat_history)
      } catch (error) {
        console.error('Error getting messages:', error)
        throw error
      }
    },
    
    async clearChatHistory({ commit }) {
      try {
        await api.post('/restart')
        commit('CLEAR_CHAT_HISTORY')
      } catch (error) {
        console.error('Error clearing chat history:', error)
        throw error
      }
    }
  },
  modules: {
  }
})
